var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dialogLoading,
          expression: "dialogLoading",
        },
      ],
      staticClass: "qmDialog",
    },
    [
      _c("head-layout", {
        staticClass: "margin-b-10",
        attrs: { "head-title": "", "head-btn-options": _vm.headOptions },
      }),
      _c(
        "div",
        { staticClass: "flex-container form-box" },
        [
          _c(
            "avue-form",
            {
              ref: "addForm",
              attrs: { option: _vm.options },
              on: { submit: _vm.treeNodeSave },
              scopedSlots: _vm._u([
                {
                  key: "isUsed",
                  fn: function ({ row }) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 0,
                          "inactive-value": 1,
                          "inactive-text":
                            _vm.form.isUsed == "1" ? "已离场" : "",
                          "active-text": _vm.form.isUsed == "0" ? "已入场" : "",
                        },
                        model: {
                          value: _vm.form.isUsed,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isUsed", $$v)
                          },
                          expression: "form.isUsed",
                        },
                      }),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c(
                "template",
                { slot: "companyName" },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      placeholder: "请输入公司名称",
                      disabled: _vm.pageType == "view",
                      "fetch-suggestions": _vm.querySearch,
                      "trigger-on-focus": false,
                      maxlength: "50",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.form.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyName", $$v)
                      },
                      expression: "form.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "companyCode" },
                [
                  _c("el-autocomplete", {
                    attrs: {
                      placeholder: "请输入统一社会信用代码",
                      disabled: _vm.pageType == "view" || _vm.isEdit,
                      "fetch-suggestions": _vm.queryCodeSearch,
                      "trigger-on-focus": false,
                      maxlength: "50",
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.form.companyCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyCode", $$v)
                      },
                      expression: "form.companyCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "template",
                { slot: "areaId" },
                [
                  _c("el-cascader", {
                    ref: "prjAreaCascader",
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择注册位置",
                      disabled: _vm.pageType == "view" || _vm.isEdit,
                      filterable: "",
                      options: _vm.areaDic,
                    },
                    on: { change: _vm.handleChangeArea },
                    model: {
                      value: _vm.form.areaId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "areaId", $$v)
                      },
                      expression: "form.areaId",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm.form.id
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "150px",
                    height: "150px",
                    "text-align": "center",
                    "z-index": "1",
                  },
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: _vm.qrcodeSrc },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.downloadQrcode.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("下载\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "shuttleBackBox" },
        [
          _c(
            "el-tabs",
            {
              on: {
                "tab-click": function ($event) {
                  return _vm.handleClick()
                },
              },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "企业证照", name: "qyzz" } }),
              _c("el-tab-pane", { attrs: { label: "人员清单", name: "ryqd" } }),
              _c("el-tab-pane", { attrs: { label: "项目记录", name: "xmjl" } }),
              _c("el-tab-pane", { attrs: { label: "奖惩记录", name: "jcjl" } }),
              _c("el-tab-pane", {
                attrs: { label: "黑名单记录", name: "hmdjl" },
              }),
            ],
            1
          ),
          _vm.activeName == "qyzz"
            ? _c("head-layout", {
                staticClass: "margin-b-10",
                attrs: {
                  "head-title": "企业证照",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-save": _vm.headSave },
              })
            : _vm._e(),
          _vm.activeName == "qyzz"
            ? _c("avue-form", {
                staticClass: "form-box",
                attrs: {
                  "upload-delete": _vm.uploadDelete,
                  "upload-after": _vm.uploadAfter,
                  option: _vm.formOption,
                  "upload-error": _vm.uploadSized,
                },
                model: {
                  value: _vm.fileForm,
                  callback: function ($$v) {
                    _vm.fileForm = $$v
                  },
                  expression: "fileForm",
                },
              })
            : _vm._e(),
          _vm.activeName != "qyzz" && _vm.showPage
            ? _c(
                "avue-crud",
                {
                  attrs: {
                    size: "mini",
                    search: _vm.search,
                    page: _vm.page,
                    option: {
                      ..._vm.option[_vm.activeName],
                      ..._vm.optionDict,
                    },
                    data: _vm.tableData,
                  },
                  on: {
                    "search-change": _vm.searchChange,
                    "search-reset": _vm.searchReset,
                    "update:search": function ($event) {
                      _vm.search = $event
                    },
                    "current-change": _vm.currentChange,
                    "size-change": _vm.sizeChange,
                    "update:page": function ($event) {
                      _vm.page = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "baseCode",
                        fn: function ({ row }) {
                          return [
                            _c("el-image", {
                              staticStyle: {
                                "padding-top": "10px",
                                width: "40px",
                              },
                              attrs: {
                                "preview-src-list": [_vm.formatter(row)],
                                src: _vm.formatter(row),
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3783944192
                  ),
                },
                [
                  _c("template", { slot: "startAgeSearch" }, [
                    _c(
                      "div",
                      { staticClass: "number-range" },
                      [
                        _c("avue-input-number", {
                          attrs: {
                            placeholder: "开始年龄",
                            clearable: "",
                            controls: false,
                          },
                          model: {
                            value: _vm.search.startProp,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "startProp", $$v)
                            },
                            expression: "search.startProp",
                          },
                        }),
                        _vm._v("-\n          "),
                        _c("avue-input-number", {
                          attrs: {
                            placeholder: "结束年龄",
                            clearable: "",
                            controls: false,
                          },
                          model: {
                            value: _vm.search.endProp,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "endProp", $$v)
                            },
                            expression: "search.endProp",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("qrcodeDialog", { ref: "qrcodeDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }